/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-content {
  width: 100%;
  height: 100%;
  background-color: white;
}
.altai-content .member-1 {
  margin-bottom: 3.25em;
}
.altai-content .member-1 h6 {
  margin: 0;
  opacity: .5;
}
.altai-content .member-1 h5 {
  margin-bottom: 0.8125em;
}
.altai-content .member-1 p {
  min-height: 6.5em;
}
@media all and (max-width: 767px) {
  .altai-content .member-1 p {
    min-height: 0;
  }
}
.altai-content .member-2 h5 {
  margin-bottom: 0.8125em;
}
.altai-content .member-2 h6 {
  margin-bottom: 0;
  opacity: .75;
}
.altai-content .member-2 .social-list {
  width: 100%;
  margin-bottom: 1.625em;
}
